import { zodResolver } from "@hookform/resolvers/zod";
import { SortingState } from "@tanstack/react-table";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  StringParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import { transformInitialFilterValues } from "@ag/components/Filters";
import { ComboBox, Select } from "@ag/design-system/molecules";
import { parseSorting } from "@ag/design-system/organisms";
import { ComboBoxField, InputField, SelectField } from "@ag/form-fields";
import { usePagination } from "@ag/utils/hooks";

import { Filters, useSearchParamForm } from "~components/filters";
import Table from "~components/table";
import { useCarbonCountries } from "~features/countries";
import { AuthorizedSidebar } from "~features/navigation";
import {
  UserManagementResourceClass,
  useUserManagementPermissions,
} from "~features/permission";
import {
  ResaleAgreementFilters,
  ResaleAgreementFiltersSchema,
  ResaleContractStatus,
  useResaleAgreementsQuery,
  useResaleAgreementsTable,
} from "~features/resale-agreements";
import { transformEnumToLabels, transformedLabelValue } from "~helpers";
import ListLayout from "~layouts/list-layout";

const ResaleAgreementsList = () => {
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "id",
      desc: true,
    },
  ]);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [pagination, updatePagination, resetPagination] = usePagination();

  const [query, setQuery] = useQueryParams({
    userId: withDefault(StringParam, undefined),
    signerName: withDefault(StringParam, undefined),
    status: withDefault(
      createEnumParam(Object.values(ResaleContractStatus)),
      undefined,
    ),
    year: withDefault(StringParam, undefined),
    countryCode: withDefault(StringParam, undefined),
  });

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ResaleAgreementFilters>({
    values: transformInitialFilterValues(query),
    resolver: zodResolver(ResaleAgreementFiltersSchema),
  });

  const {
    handleClearFiltersBar,
    handleClearFiltersDrawer,
    handleSubmitFilters,
  } = useSearchParamForm<ResaleAgreementFilters>(
    query,
    setQuery,
    resetPagination,
    () => setIsFiltersOpen(false),
  );

  const { data: countries } = useCarbonCountries();

  const { data: userManagementPermissions } = useUserManagementPermissions();
  const canViewUser = userManagementPermissions?.read?.includes(
    UserManagementResourceClass.User,
  );

  const renderFilterBarItem = (
    key: keyof ResaleAgreementFilters,
    value: ResaleAgreementFilters[keyof ResaleAgreementFilters],
  ) => {
    const label = (
      {
        userId: "User ID",
        signerName: "Signer Name",
        status: "Contract Status",
        countryCode: "Country",
        year: "Year",
      } as unknown as Record<keyof ResaleAgreementFilters, string>
    )[key];

    return `${label}: ${transformedLabelValue(value)}`;
  };

  const resaleAgreementStatusLabels =
    transformEnumToLabels(ResaleContractStatus);

  const { data, isLoading } = useResaleAgreementsQuery({
    ...pagination,
    filters: {
      ...query,
    },
    sort: parseSorting(sorting),
  });

  const table = useResaleAgreementsTable(
    data?.items,
    {
      sorting,
      setSorting,
    },
    Boolean(canViewUser),
  );

  const handleFormFiltersClear = () => {
    reset();
    handleClearFiltersDrawer();
  };

  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <ListLayout.TopBarTitle>Resale Agreements</ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />
        <Filters.Drawer
          isOpen={isFiltersOpen}
          onSubmit={handleSubmit(handleSubmitFilters)}
          onClose={() => setIsFiltersOpen(false)}
          onClear={handleFormFiltersClear}
        >
          <InputField {...register("userId")} label="User ID" />

          <InputField {...register("signerName")} label="Signer Name" />

          <Controller
            name="status"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                {...fieldState}
                value={field.value ?? ""}
                label="Contract Status"
                optionsClassName="z-modal"
              >
                <Select.OptionAll>All</Select.OptionAll>

                {Object.values(ResaleContractStatus).map(type => (
                  <Select.Option key={type} value={type}>
                    {resaleAgreementStatusLabels[type]}
                  </Select.Option>
                ))}
              </SelectField>
            )}
          />

          <Controller
            name="countryCode"
            control={control}
            render={({ field, fieldState }) => (
              <ComboBoxField
                {...field}
                {...fieldState}
                value={field.value ?? ""}
                label="Country"
                loadingText="Loading..."
                emptyText="No matching results"
                optionsClassName="z-modal"
              >
                {countries?.map(country => (
                  <ComboBox.Item key={country.code}>
                    {country.name}
                  </ComboBox.Item>
                ))}
              </ComboBoxField>
            )}
          />

          <InputField
            {...register("year")}
            label="Year"
            type="number"
            error={errors.year}
          />
        </Filters.Drawer>
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <ListLayout.Header>
          <Filters.Bar
            values={query}
            renderItem={renderFilterBarItem}
            onToggleOpen={() => setIsFiltersOpen(value => !value)}
            onClear={handleClearFiltersBar}
          />
        </ListLayout.Header>

        <Table
          instance={table}
          meta={data?.meta}
          pagination={pagination}
          isLoading={isLoading}
          onPaginationChange={updatePagination}
        />
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default ResaleAgreementsList;
