import { AriaListBoxOptions } from "@react-aria/listbox";
import {
  CSSProperties,
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { mergeProps, useListBox, useOption } from "react-aria";
import { mergeRefs, useLayer } from "react-laag";
import { Item, ListProps, ListState, Node, useListState } from "react-stately";

import { Icon } from "~assets";
import { Checkbox } from "~atoms";
import { cn } from "~utils";

import { useMultiSelect } from "./use-multi-select";

/**
 * For some reason this type is not exposed yet:
 * https://github.com/adobe/react-spectrum/issues/4138
 */
type CollectionChildren = Parameters<typeof useListState>[0]["children"];

export type Selection = Node<object>[];

/* -------------------------------------------------------------------------------------------------
 * Root
 * -----------------------------------------------------------------------------------------------*/

type MultiSelectContentCSSProperties = CSSProperties &
  Record<"--trigger-width", string>;

export type MultiSelectProps<TValue extends string> =
  React.PropsWithChildrenRequired<{
    renderSelection: (selection: Selection) => React.ReactNode;
    ariaLabel?: string;
    ariaLabelledby?: string;
    placeholder?: string;
    optionsClassName?: string;
    values?: TValue[] | null;
    variant?: "default" | "full-width";
    isDisabled?: boolean;
    isInvalid?: boolean;
    onChange: (values: TValue[] | null) => void;
  }>;

function MultiSelect<TValue extends string>(
  {
    children,
    placeholder,
    optionsClassName,
    renderSelection,
    ariaLabel,
    ariaLabelledby,
    values,
    variant,
    isDisabled,
    isInvalid,
    onChange,
  }: MultiSelectProps<TValue>,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const popoverRef = useRef<HTMLElement>(null);
  const listRef = useRef<HTMLUListElement>(null);

  const [triggerWidth, setTriggerWidth] = useState<number | null>(null);

  const selectedKeys = useMemo(() => new Set(values), [values]);

  const updateTriggerWidth = useCallback(
    (node: HTMLButtonElement | null) =>
      setTriggerWidth(node?.clientWidth ?? null),
    [],
  );

  const listState = useListState<object>({
    children: children as CollectionChildren,
    selectedKeys,
    selectionBehavior: "toggle",
    selectionMode: "multiple",
    onSelectionChange: selection => {
      if (selection === "all") {
        onChange(Array.from(listState.collection.getKeys() as TValue[]));
      } else {
        onChange(Array.from(selection) as TValue[]);
      }
    },
  });

  const selectedItems = useMemo(
    () =>
      Array.from(listState.selectionManager.selectedKeys).map(key =>
        listState.collection.getItem(key),
      ) as Node<object>[],
    [listState.collection, listState.selectionManager.selectedKeys],
  );

  const { menuProps, triggerState, triggerProps } = useMultiSelect(
    { isDisabled },
    listState,
    triggerRef,
  );

  const {
    triggerProps: layerTriggerProps,
    layerProps,
    renderLayer,
  } = useLayer({
    container: () => document.body,
    isOpen: !isDisabled && triggerState.isOpen,
    onOutsideClick: () => triggerState.setOpen(false),
    placement: "bottom-start",
    triggerOffset: 4,
  });

  return (
    <>
      <div
        {...triggerProps}
        ref={mergeRefs(
          triggerRef,
          layerTriggerProps.ref,
          updateTriggerWidth,
          ref,
        )}
        aria-disabled={isDisabled}
        aria-invalid={isInvalid}
        className={cn(
          "text-input",
          "p-[9px] pe-4 ps-4",

          "place-items-[center_flex-start] inline-grid grid-flow-col gap-2",
          "min-h-[42px] min-w-[17ch] max-w-[32ch]",
          "overflow-hidden whitespace-nowrap",

          "rounded border border-grey-700 bg-white-100",

          variant === "full-width" && "max-w-full",
          "aria-disabled:border-grey-200 aria-disabled:bg-grey-200",
          "aria-[invalid=true]:border-red-700",
        )}
        tabIndex={isDisabled ? -1 : 0}
      >
        {selectedItems.length ? (
          <span
            className={cn(
              "flex flex-wrap gap-1",
              "w-full overflow-hidden text-ellipsis whitespace-nowrap",
            )}
          >
            {renderSelection(selectedItems)}
          </span>
        ) : (
          <span>{placeholder ?? "-"}</span>
        )}

        {!isDisabled ? (
          <button
            type="button"
            className={cn(
              "justify-self-end",
              "-m-2 ml-0 p-2",
              "leading-none border-none bg-none",
            )}
            tabIndex={-1}
          >
            <Icon name={triggerState.isOpen ? "chevron-up" : "chevron-down"} />
          </button>
        ) : null}
      </div>

      {triggerState.isOpen
        ? renderLayer(
            <div
              {...layerProps}
              ref={mergeRefs(layerProps.ref, popoverRef)}
              className={cn(
                "z-low min-w-[--trigger-width] overflow-y-auto rounded",
                "max-h-96 border border-grey-700 bg-white-100 py-[9px] text-grey-900 shadow-200",
                optionsClassName,
              )}
              style={
                {
                  ...layerProps.style,
                  ["--trigger-width"]: triggerWidth
                    ? `${triggerWidth}px`
                    : undefined,
                } as MultiSelectContentCSSProperties
              }
            >
              <MultiSelectListBox
                listRef={listRef}
                {...menuProps}
                state={listState}
                ariaLabel={ariaLabel}
                ariaLabelledby={ariaLabelledby}
                onBlur={triggerState.close}
              />
            </div>,
          )
        : null}
    </>
  );
}

/* -------------------------------------------------------------------------------------------------
 * MultiSelectListBox
 * -----------------------------------------------------------------------------------------------*/
interface MultiSelectListBoxProps
  extends ListProps<any>,
    AriaListBoxOptions<any> {
  listRef: React.RefObject<HTMLUListElement>;
  state: ListState<object>;
  ariaLabel?: string;
  ariaLabelledby?: string;
  onBlur: () => void;
}

export default function MultiSelectListBox({
  ariaLabel,
  ariaLabelledby,
  listRef,
  state,
  onBlur,
  ...rest
}: MultiSelectListBoxProps) {
  /**
   * This component + hook is needed, as the ListBox is being conditionally rendered,
   * and otherwise it will fail when the ref is null.
   */
  const { listBoxProps } = useListBox(
    {
      "aria-label": ariaLabel,
      "aria-labelledby": ariaLabelledby,
      shouldFocusOnHover: true,
      onBlur,
    },
    state,
    listRef,
  );

  return (
    <ul
      ref={listRef}
      className="m-0 list-none p-0"
      {...(mergeProps(
        listBoxProps,
        rest,
      ) as React.HTMLAttributes<HTMLUListElement>)}
    >
      {Array.from(state.collection).map(item => (
        <MultiSelectOption state={state} item={item} key={item.key} />
      ))}
    </ul>
  );
}

/* -------------------------------------------------------------------------------------------------
 * MultiSelectOption
 * -----------------------------------------------------------------------------------------------*/
type MultiSelectOptionProps = {
  item: Node<object>;
  state: ListState<object>;
};

function MultiSelectOption({ item, state }: MultiSelectOptionProps) {
  const ref = useRef(null);

  const { optionProps, isSelected, isDisabled, isFocused } = useOption(
    { key: item.key },
    state,
    ref,
  );

  return (
    <li
      {...optionProps}
      ref={ref}
      className={cn(
        "flex items-center gap-2 overflow-hidden",
        "p-[6px] pe-4 ps-4",
        "cursor-pointer list-none text-ellipsis whitespace-nowrap leading-p1 text-grey-900",

        "data-[disabled=true]:pointer-events-none data-[disabled=true]:text-grey-700",
        "data-[highlighted=true]:bg-grey-200 data-[highlighted=true]:outline-none",
      )}
      data-highlighted={isFocused}
      data-disabled={isDisabled}
    >
      {/* This prevent is needed or the option will trigger a double toggle */}
      <Checkbox
        checked={isSelected}
        onClick={event => event.stopPropagation()}
      />

      {item.rendered}
    </li>
  );
}

const Root = forwardRef(MultiSelect);

export { Root, Item };
