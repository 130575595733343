import { Route, Routes } from "react-router-dom";

import { AuthorizedRoute } from "~features/auth";
import { FieldHarvestYearContextProvider } from "~features/harvest-year";

import Page404 from "./404";
import Account from "./_legacy/account";
import CropTypeEdit from "./_legacy/carbon/pages/CropTypes/pages/CropTypeEdit";
import EditFertiliser from "./_legacy/carbon/pages/Fertilisers/pages/EditFertiliser";
import EditField from "./_legacy/carbon/pages/Fields/pages/EditField";
import LandingPage from "./_legacy/landing-page";
import ApplicationSettingDetails from "./_legacy/settings/pages/ApplicationSettings/pages/ApplicationSettingDetails";
import ApplicationSettingsList from "./_legacy/settings/pages/ApplicationSettings/pages/ApplicationSettingsList";
import Translations from "./_legacy/settings/pages/translations";
import AddAdmin from "./_legacy/users/pages/Admins/AddAdmin";
import Admins from "./admins";
import AdminDetails from "./admins/[adminId]";
import AssignedUsers from "./assigned-users";
import Assurance from "./carbon/assurance";
import QualityAssuranceBulk from "./carbon/assurance/bulk";
import BulkCQAEdits from "./carbon/assurance/bulk/[spaceId]";
import BoundaryVerification from "./carbon/boundary-verification";
import BoundaryManagementMap from "./carbon/boundary-verification/boundary-management-map";
import BuyoutContractsList from "./carbon/buyout-agreements";
import BuyoutContractsDetails from "./carbon/buyout-agreements/[id]";
import Contracts from "./carbon/contracts";
import ContractDetails from "./carbon/contracts/[contractId]";
import CropTypes from "./carbon/crop-types";
import Fertilisers from "./carbon/fertilisers";
import Fields from "./carbon/fields";
import FieldDetails from "./carbon/fields/[fieldId]";
import QualityControl from "./carbon/quality-control";
import QualityControlBulk from "./carbon/quality-control/bulk";
import BulkCQCEdits from "./carbon/quality-control/bulk/[spaceId]";
import BulkCQCFertilisersEdits from "./carbon/quality-control/bulk/fertilisers/[spaceId]";
import ResaleAgreementsList from "./carbon/resale-agreements";
import { ResaleAgreement } from "./carbon/resale-agreements/[id]";
import SalesOffersList from "./carbon/sales-offers";
import SalesOfferDetails from "./carbon/sales-offers/[salesOfferId]";
import AgreementConfirmationPage from "./carbon/sales-offers/new/[id]";
import ValidationRequests from "./carbon/validation-requests";
import ValidationRequestFields from "./carbon/validation-requests/[validationRequestId]/fields";
import CertificatesManagement from "./certificates-management";
import ImportVcu from "./certificates-management/import-vcu";
import ImportIsoCertificates from "./certificates-management/import-vcu/iso";
import ImportVerraCertificates from "./certificates-management/import-vcu/verra";
import UpdateRequest from "./certificates-management/update-request/[requestId]";
import ApproveUpdateRequest from "./certificates-management/update-request/[requestId]/approve";
import Crops from "./crops";
import CropDetails from "./crops/[id]";
import ConsentApproved from "./docusign/consent-approved";
import Ferilisers from "./fertilisers";
import FertiliserDetails from "./fertilisers/[id]";
import File from "./file";
import InventoryImports from "./inventory-imports";
import Login from "./login";
import ResetPassword from "./reset-password";
import ResetPasswordProcess from "./reset-password/process";
import Users from "./users";
import UserDetails from "./users/[userId]";
import UserEdit from "./users/[userId]/edit";
import AddUser from "./users/add";

const Pages = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      {/* Reset password */}
      <Route path="reset-password" element={<ResetPassword />} />
      <Route path="reset-password/process" element={<ResetPasswordProcess />} />
      {/* Users */}
      <Route
        path="users"
        element={
          <AuthorizedRoute>
            <Users />
          </AuthorizedRoute>
        }
      />
      <Route
        path="users/:userId"
        element={
          <AuthorizedRoute>
            <UserDetails />
          </AuthorizedRoute>
        }
      />
      <Route
        path="users/add"
        element={
          <AuthorizedRoute>
            <AddUser />
          </AuthorizedRoute>
        }
      />
      <Route
        path="users/:userId/edit"
        element={
          <AuthorizedRoute>
            <UserEdit />
          </AuthorizedRoute>
        }
      />
      {/* Admins */}
      <Route
        path="admins/add"
        element={
          <AuthorizedRoute>
            <AddAdmin />
          </AuthorizedRoute>
        }
      />
      <Route
        path="admins/:id"
        element={
          <AuthorizedRoute>
            <AdminDetails />
          </AuthorizedRoute>
        }
      />
      <Route
        path="admins/*"
        element={
          <AuthorizedRoute>
            <Admins />
          </AuthorizedRoute>
        }
      />
      {/* Fields */}
      <Route
        path="carbon/fields/:fieldId"
        element={
          <AuthorizedRoute>
            <FieldHarvestYearContextProvider>
              <FieldDetails />
            </FieldHarvestYearContextProvider>
          </AuthorizedRoute>
        }
      />
      <Route
        path="carbon/fields/*"
        element={
          <AuthorizedRoute>
            <Fields />
          </AuthorizedRoute>
        }
      />
      {/* Buyout Agreements */}
      <Route
        path="carbon/agreements/buyout"
        element={
          <AuthorizedRoute>
            <BuyoutContractsList />
          </AuthorizedRoute>
        }
      />
      <Route
        path="carbon/agreements/buyout/:id"
        element={
          <AuthorizedRoute>
            <BuyoutContractsDetails />
          </AuthorizedRoute>
        }
      />
      {/* Sales offers */}
      <Route
        path="carbon/agreements/offers"
        element={
          <AuthorizedRoute>
            <SalesOffersList />
          </AuthorizedRoute>
        }
      />
      {/* Sales offer details */}
      <Route
        path="carbon/agreements/offers/:salesOfferId"
        element={
          <AuthorizedRoute>
            <SalesOfferDetails />
          </AuthorizedRoute>
        }
      />
      {/* Agreement Confirmation */}
      <Route
        path="carbon/agreements/new/:id"
        element={
          <AuthorizedRoute>
            <AgreementConfirmationPage />
          </AuthorizedRoute>
        }
      />
      {/* Resale Agreements */}
      <Route
        path="carbon/agreements/resale"
        element={
          <AuthorizedRoute>
            <ResaleAgreementsList />
          </AuthorizedRoute>
        }
      />
      {/* Resale Agreement details*/}
      <Route
        path="carbon/agreements/resale/:id"
        element={
          <AuthorizedRoute>
            <ResaleAgreement />
          </AuthorizedRoute>
        }
      />
      {/* Contracts */}
      <Route
        path="carbon/contracts"
        element={
          <AuthorizedRoute>
            <Contracts />
          </AuthorizedRoute>
        }
      />
      <Route
        path="carbon/contracts/:contractId"
        element={
          <AuthorizedRoute>
            <ContractDetails />
          </AuthorizedRoute>
        }
      />

      {/* External partners */}
      <Route
        path="assigned-users"
        element={
          <AuthorizedRoute>
            <AssignedUsers />
          </AuthorizedRoute>
        }
      />
      {/* -------------------------------------------------------------------------------------------------
       * LEGACY PAGES
       * -----------------------------------------------------------------------------------------------*/}
      <Route
        path="/"
        element={
          <AuthorizedRoute>
            <LandingPage />
          </AuthorizedRoute>
        }
      />
      <Route
        path="account"
        element={
          <AuthorizedRoute>
            <Account />
          </AuthorizedRoute>
        }
      />
      {/* Settings */}
      <Route
        path="settings/application"
        element={
          <AuthorizedRoute>
            <ApplicationSettingsList />
          </AuthorizedRoute>
        }
      />
      <Route
        path="settings/application/:id"
        element={
          <AuthorizedRoute>
            <ApplicationSettingDetails />
          </AuthorizedRoute>
        }
      />
      <Route
        path="settings/translations"
        element={
          <AuthorizedRoute>
            <Translations />
          </AuthorizedRoute>
        }
      />
      {/* Carbon */}
      <Route
        path="carbon/fields/:id/edit"
        element={
          <AuthorizedRoute>
            <EditField />
          </AuthorizedRoute>
        }
      />
      <Route
        path="carbon/validation-requests"
        element={
          <AuthorizedRoute>
            <ValidationRequests />
          </AuthorizedRoute>
        }
      />
      <Route
        path="carbon/validation-requests/:validationRequestId/fields"
        element={
          <AuthorizedRoute>
            <ValidationRequestFields />
          </AuthorizedRoute>
        }
      />
      <Route
        path="carbon/boundary-verification"
        element={
          <AuthorizedRoute>
            <BoundaryVerification />
          </AuthorizedRoute>
        }
      />
      <Route
        path="carbon/boundary-verification/map/:farmId"
        element={
          <AuthorizedRoute>
            <BoundaryManagementMap />
          </AuthorizedRoute>
        }
      />
      <Route
        path="carbon/assurance"
        element={
          <AuthorizedRoute>
            <Assurance />
          </AuthorizedRoute>
        }
      />
      <Route
        path="carbon/assurance/bulk"
        element={
          <AuthorizedRoute>
            <QualityAssuranceBulk />
          </AuthorizedRoute>
        }
      />
      <Route
        path="carbon/assurance/bulk/:spaceId"
        element={
          <AuthorizedRoute>
            <BulkCQAEdits />
          </AuthorizedRoute>
        }
      />
      <Route
        path="carbon/cq-control"
        element={
          <AuthorizedRoute>
            <QualityControl />
          </AuthorizedRoute>
        }
      />
      <Route
        path="carbon/cq-control/bulk"
        element={
          <AuthorizedRoute>
            <QualityControlBulk />
          </AuthorizedRoute>
        }
      />
      <Route
        path="carbon/cq-control/bulk/:spaceId"
        element={
          <AuthorizedRoute>
            <BulkCQCEdits />
          </AuthorizedRoute>
        }
      />
      <Route
        path="carbon/cq-control/bulk/fertilisers/:spaceId"
        element={
          <AuthorizedRoute>
            <BulkCQCFertilisersEdits />
          </AuthorizedRoute>
        }
      />
      <Route
        path="carbon/fertilisers/:id/edit"
        element={
          <AuthorizedRoute>
            <EditFertiliser />
          </AuthorizedRoute>
        }
      />

      {/* Fertilisers - OLD */}
      <Route
        path="carbon/fertilisers"
        element={
          <AuthorizedRoute>
            <Fertilisers />
          </AuthorizedRoute>
        }
      />
      <Route
        path="carbon/fertilisers/:id/edit"
        element={
          <AuthorizedRoute>
            <CropTypeEdit />
          </AuthorizedRoute>
        }
      />

      {/* Crop types - OLD */}
      <Route
        path="carbon/crop-types"
        element={
          <AuthorizedRoute>
            <CropTypes />
          </AuthorizedRoute>
        }
      />
      <Route
        path="carbon/crop-types/:id/edit"
        element={
          <AuthorizedRoute>
            <CropTypeEdit />
          </AuthorizedRoute>
        }
      />

      {/* Fertilisers V2 */}
      <Route
        path="fertilisers"
        element={
          <AuthorizedRoute>
            <Ferilisers />
          </AuthorizedRoute>
        }
      />
      <Route
        path="fertilisers/:id"
        element={
          <AuthorizedRoute>
            <FertiliserDetails />
          </AuthorizedRoute>
        }
      />

      {/* Crops V2 */}
      <Route
        path="crops"
        element={
          <AuthorizedRoute>
            <Crops />
          </AuthorizedRoute>
        }
      />
      <Route
        path="crops/:id"
        element={
          <AuthorizedRoute>
            <CropDetails />
          </AuthorizedRoute>
        }
      />

      <Route
        path="certificates-management"
        element={
          <AuthorizedRoute>
            <CertificatesManagement />
          </AuthorizedRoute>
        }
      />
      <Route
        path="certificates-management/update-request/:requestId"
        element={
          <AuthorizedRoute>
            <UpdateRequest />
          </AuthorizedRoute>
        }
      />
      <Route
        path="certificates-management/update-request/:requestId/approve"
        element={
          <AuthorizedRoute>
            <ApproveUpdateRequest />
          </AuthorizedRoute>
        }
      />
      <Route
        path="certificates-management/import"
        element={
          <AuthorizedRoute>
            <ImportVcu />
          </AuthorizedRoute>
        }
      />
      <Route
        path="certificates-management/import/iso"
        element={
          <AuthorizedRoute>
            <ImportIsoCertificates />
          </AuthorizedRoute>
        }
      />
      <Route
        path="certificates-management/import/verra"
        element={
          <AuthorizedRoute>
            <ImportVerraCertificates />
          </AuthorizedRoute>
        }
      />
      <Route
        path="inventory-imports"
        element={
          <AuthorizedRoute>
            <InventoryImports />
          </AuthorizedRoute>
        }
      />
      <Route
        path="file"
        element={
          <AuthorizedRoute>
            <File />
          </AuthorizedRoute>
        }
      />
      <Route path="docusign/consent-approved" element={<ConsentApproved />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default Pages;
