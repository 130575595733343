import { AuthorizedSidebar } from "~features/navigation";
import { withFeatureFlagAccess } from "~hocs/withFeatureFlagAccess";
import ListLayout from "~layouts/list-layout";

const Crops = () => {
  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <ListLayout.TopBarTitle>Crops</ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />
        {/* TODO => Filters */}
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <ListLayout.Header variant="slim">
          <ListLayout.Actions>
            <div />
            {/* TODO: Create crop button */}
          </ListLayout.Actions>
        </ListLayout.Header>

        {/* TODO: Crops Table with HY tabs */}
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default withFeatureFlagAccess("crops-v2", {
  redirectPath: "/carbon/crop-types",
})(Crops);
