import { useKey } from "rooks";

import { Icon } from "@ag/design-system/assets";
import { colorsObject } from "@ag/design-system/tokens";
import { cn } from "@ag/design-system/utils";

import IconCut from "~assets/icon-cut";
import IconNavigationArrow from "~assets/icon-navigation-arrow";
import { HistoryStack } from "~hooks/use-multi-polygon-paths-history";

import { getActiveVertexPath, getActiveVertexPosition } from "../../helpers";
import { useMultiPolygonBoundariesStore } from "../multiPolygonStore";

type Props = {
  history: HistoryStack;
  onVertexDelete: (polygonId: number, path: number) => void;
};

type ButtonProps = {
  icon: JSX.Element;
  text: string;
  variant: "primary" | "secondary" | "text";
  isActive?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
};

const Button = ({
  icon,
  text,
  variant,
  isActive = false,
  isDisabled = false,
  onClick,
}: ButtonProps) => {
  const variants = {
    primary: {
      active:
        "bg-messaging-warning-700 text-white-100 border border-solid border-messaging-warning-900",
      inActive:
        "text-messaging-warning-900 bg-[#FDD777] border border-solid border-messaging-warning-900 hover:bg-[#FFE5A4]",
    },
    secondary:
      "bg-messaging-warning-500 text-messaging-error-900 border border-solid border-messaging-error-900 hover:bg-[#FFE5A4]",
    text: "text-messaging-warning-900 bg-messaging-warning-500 hover:bg-[#FFE5A4]",
  };

  const className = cn(
    "text-h5 flex items-center gap-1 rounded px-2 py-1.5",
    typeof variants[variant] === "string"
      ? variants[variant]
      : isActive
        ? (variants[variant] as { active: string; inActive: string }).active
        : (variants[variant] as { active: string; inActive: string }).inActive,
  );

  return (
    <button onClick={onClick} className={className} disabled={isDisabled}>
      {icon}
      {text}
    </button>
  );
};

const MultiPolygonEditingToolbar = ({ history, onVertexDelete }: Props) => {
  const {
    activeVertex,
    editAction,
    polygonInstance,
    outerPath,
    innerPaths,
    setActiveVertex,
    setEditAction,
    setOuterPath,
    setInnerPaths,
  } = useMultiPolygonBoundariesStore();

  const activeVertexPath =
    activeVertex &&
    getActiveVertexPath(
      activeVertex,
      outerPath[activeVertex.polygonId],
      innerPaths[activeVertex.polygonId],
    );

  const activeVertexPosition =
    activeVertex &&
    getActiveVertexPosition(
      activeVertex,
      outerPath[activeVertex.polygonId],
      innerPaths[activeVertex.polygonId],
    );

  // Allow deleting vertex if polygon has more than 3 vertices
  const isDeleteVertexAvailable = Boolean(
    activeVertexPosition &&
      activeVertexPath?.length &&
      activeVertexPath.length > 3,
  );
  const isUndoAvailable = history.itemsAmount > 0;

  const handleDeleteVertex = () => {
    if (!activeVertex || !polygonInstance) return;

    const { path, vertex } = activeVertex;

    polygonInstance[activeVertex.polygonId]
      .getPaths()
      .getAt(path)
      .removeAt(vertex);

    onVertexDelete(activeVertex.polygonId, path);

    setActiveVertex(undefined);
  };

  useKey(["Delete", "Backspace"], () => {
    isDeleteVertexAvailable && handleDeleteVertex();
  });

  const handleUndoClicked = () => {
    const previousChange = history.pop();

    if (previousChange) {
      const { outerPath, innerPaths } = previousChange;

      setOuterPath(outerPath);
      setInnerPaths(innerPaths);
      setActiveVertex(undefined);
    }
  };

  const handleSelectModeClicked = () => {
    if (editAction === "cut") {
      setEditAction("edit");
      setActiveVertex(undefined);
    }
  };

  const handleCutModeToggleClicked = () => {
    if (editAction === "edit") {
      setEditAction("cut");
      setActiveVertex(undefined);
    }
  };

  if (!outerPath) return null;

  return (
    <div className="bg-messaging-warning-500 text-messaging-warning-900 absolute flex w-full flex-row justify-between p-1.5">
      <div className="flex gap-2">
        <Button
          icon={
            <IconNavigationArrow
              color={
                editAction === "edit"
                  ? colorsObject.white[100]
                  : colorsObject.messaging.warning[900]
              }
            />
          }
          text="Single select"
          variant="primary"
          isActive={editAction === "edit"}
          onClick={handleSelectModeClicked}
        />
        <Button
          icon={
            <IconCut
              color={
                editAction === "cut"
                  ? colorsObject.white[100]
                  : colorsObject.messaging.warning[900]
              }
            />
          }
          text="Cut"
          isActive={editAction === "cut"}
          variant="primary"
          onClick={handleCutModeToggleClicked}
        />
      </div>
      <div className="flex flex-row gap-2">
        <Button
          icon={
            <Icon
              color={colorsObject.messaging.warning[900]}
              name="arrow-rotate-back"
            />
          }
          text="Undo"
          variant="text"
          isDisabled={!isUndoAvailable}
          onClick={handleUndoClicked}
        />

        <Button
          icon={
            <Icon color={colorsObject.messaging.error[900]} name="trash-can" />
          }
          text="Delete"
          variant="secondary"
          onClick={handleDeleteVertex}
        />
      </div>
    </div>
  );
};

export default MultiPolygonEditingToolbar;
