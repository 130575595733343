export enum Page {
  // Users
  UserValidation = "USER_VALIDATION",
  Admins = "ADMINS",
  AssignedUsers = "ASSIGNED_USERS",

  // Carbon operations
  Fields = "FIELDS",
  ValidationRequests = "VALIDATION_REQUESTS",
  CarbonContracts = "CARBON_CONTRACTS",

  // Sales agreements
  SalesOffers = "SALES_OFFERS",
  ResaleAgreements = "RESALE_AGREEMENTS",
  BuyoutContracts = "BUYOUT_CONTRACTS",

  // Carbon settings
  CropTypes = "CROP_TYPES",
  Fertilisers = "FERTILISERS",

  // Carbon MRV
  QualityAssurance = "QUALITY_ASSURANCE",
  QualityControl = "QUALITY_CONTROL",
  BoundaryVerification = "BOUNDARY_VERIFICATION",

  // Assets
  CertificatesManagement = "CERTIFICATES_MANAGEMENT",
  ImportVcu = "IMPORT_VCU",
  ImportIsoCertificates = "IMPORT_ISO_CERTIFICATES",
  ImportVerraCertificates = "IMPORT_VERRA_CERTIFICATES",
  UpdateRequest = "UPDATE_REQUEST",
  ApproveUpdateRequest = "APPROVE_UPDATE_REQUEST",

  // Inventory
  InventoryImports = "INVENTORY_IMPORTS",

  // Settings
  ApplicationSettings = "APPLICATION_SETTINGS",
  Translations = "TRANSLATIONS",

  // External
  Features = "FEATURES",
  DocuSign = "DOCUSIGN",
  SideKiq = "SIDEKIQ",
}
