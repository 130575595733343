import { AuthorizedSidebar } from "~features/navigation";
import { withFeatureFlagAccess } from "~hocs/withFeatureFlagAccess";
import DetailsLayout from "~layouts/details-layout";

const CropDetails = () => {
  return (
    <DetailsLayout.Root>
      <DetailsLayout.Sidebar>
        <AuthorizedSidebar />
      </DetailsLayout.Sidebar>

      <DetailsLayout.TopBar>
        <DetailsLayout.TopBarTitle>Crop details</DetailsLayout.TopBarTitle>
      </DetailsLayout.TopBar>

      <DetailsLayout.Content>
        <div />
      </DetailsLayout.Content>
    </DetailsLayout.Root>
  );
};

export default withFeatureFlagAccess("crops-v2", {
  redirectPath: "/carbon/crop-types",
})(CropDetails);
