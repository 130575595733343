import { ExportToCsv } from "@agreena-aps/export-to-csv";
import { RowSelectionState, SortingState } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { StringParam } from "use-query-params";

import { useFilters } from "@ag/components/Filters";
import { Modal } from "@ag/components/Modal";
import { Confirmation, parseSorting } from "@ag/design-system/organisms";
import I18n from "@ag/i18n";
import { useDialog, usePagination } from "@ag/utils/hooks";

import Table from "~components/table";
import {
  FieldsActions,
  FieldsBulkActionType,
  FieldsFilters,
  mapFieldsToCSVData,
  useFieldsBulkActions,
  useFieldsQuery,
  useFieldsTable,
} from "~features/field";
import { UpdateFieldNamesModal } from "~features/field/components/update-field-names-modal/update-field-names-modal";
import { AuthorizedSidebar } from "~features/navigation";
import {
  CarbonResourceClass,
  useCarbonPermissions,
} from "~features/permission";
import ListLayout from "~layouts/list-layout";

const filtersConfig = {
  userId: [StringParam, { isDebounced: true }],
  id: [StringParam, { isDebounced: true }],
  fieldName: [StringParam, { isDebounced: true }],
  actualSubmitted: StringParam,
  fallowActualsYears: StringParam,
  definitionSubmitted: StringParam,
  activeContract: StringParam,
  expired: StringParam,
} as const;

const Fields = () => {
  const [selection, setSelection] = useState<RowSelectionState>({});
  const [sorting, setSorting] = useState<SortingState>([
    { id: "id", desc: true },
  ]);

  const [pagination, updatePagination, resetPagination] = usePagination();

  const {
    selectedFields,
    bulkDeleteFields,
    bulkUnlockDefinitions,
    bulkUnlockActuals,
    bulkEditFieldnames,
    setSelectedFields,
    bulkExpireFields,
    bulkUnexpireFields,
  } = useFieldsBulkActions();

  const filters = useFilters(filtersConfig);

  const {
    userId,
    id,
    fieldName,
    actualSubmitted,
    fallowActualsYears,
    activeContract,
    definitionSubmitted,
    expired,
  } = filters.values;

  const { data: carbonPermissions } = useCarbonPermissions();
  const { data: fieldsData, isLoading } = useFieldsQuery({
    ...pagination,
    filters: {
      userId: userId ? Number(userId) : undefined,
      id: id ? [id] : undefined,
      fieldName,
      actualSubmitted,
      fallowActualsYears: fallowActualsYears ? [fallowActualsYears] : undefined,
      definitionSubmitted: definitionSubmitted
        ? definitionSubmitted === "true"
        : undefined,
      withoutActiveContract: activeContract
        ? activeContract === "false"
        : undefined,
      expired: expired ? expired === "true" : undefined,
    },
    sort: parseSorting(sorting),
  });

  const bulkActionAccess = useMemo(() => {
    const access: FieldsBulkActionType[] = [];

    // TODO: remove
    if (
      carbonPermissions?.unlock?.includes(
        CarbonResourceClass.CarbonFieldDefinition,
      )
    ) {
      access.push("unlock-definition");
    }

    if (
      carbonPermissions?.unlock?.includes(CarbonResourceClass.CarbonFieldActual)
    ) {
      access.push("unlock-actuals");
    }

    if (
      carbonPermissions?.deleteBulk?.includes(CarbonResourceClass.CarbonField)
    ) {
      access.push("delete");
    }

    if (
      carbonPermissions?.updateBulk?.includes(CarbonResourceClass.CarbonField)
    ) {
      access.push("bulk-edit-field-name");
      access.push("expire");
      access.push("unexpire");
    }

    return access;
  }, [carbonPermissions]);

  const table = useFieldsTable(fieldsData?.items, {
    selection,
    sorting,
    isSelectionEnabled: bulkActionAccess.length > 0,
    setSelection: selectionOrUpdater => {
      if (!fieldsData) return;

      const lookup =
        typeof selectionOrUpdater === "function"
          ? selectionOrUpdater(selection)
          : selectionOrUpdater;

      setSelectedFields(
        fieldsData?.items.filter(field => lookup[field.id] === true),
      );
    },
    setSorting,
  });

  useMemo(() => {
    const lookup: Record<string, boolean> = {};
    for (const field of selectedFields) {
      lookup[field.id] = true;
    }
    setSelection(lookup);
  }, [selectedFields]);

  const { show: showDeleteConfirmationDialog } = useDialog(({ hide }) => (
    <Modal isOpen noPadding onRequestClose={hide}>
      <Confirmation.Root variant="danger">
        <Confirmation.Title>
          {I18n.t("js.carbon.fields.action.delete_fields")}
        </Confirmation.Title>

        <Confirmation.Description>
          {I18n.t("js.fields.delete_fields_confirmation")}
        </Confirmation.Description>

        <Confirmation.Actions>
          <Confirmation.Cancel onClick={hide}>
            {I18n.t("js.shared.cancel")}
          </Confirmation.Cancel>

          <Confirmation.Confirm
            onClick={() =>
              bulkDeleteFields({
                onSuccess: () => {
                  hide();
                },
              })
            }
          >
            {I18n.t("js.shared.delete")}
          </Confirmation.Confirm>
        </Confirmation.Actions>
      </Confirmation.Root>
    </Modal>
  ));

  const { show: showBulkEditFieldNamesModal } = useDialog(({ hide }) => (
    <UpdateFieldNamesModal
      fields={selectedFields}
      isOpen
      onSaveNames={bulkEditFieldnames}
      onRequestClose={hide}
    />
  ));

  const handlePaginationChanged = (value: {
    limit?: number;
    page?: number;
  }) => {
    updatePagination(value);
    setSelection({});
  };

  const handleExportToCSV = () => {
    const dataForCSV = mapFieldsToCSVData(fieldsData?.items);

    const csvExporter = new ExportToCsv({
      showLabels: true,
      useKeysAsHeaders: true,
      filename: "carbon-fields-changes",
    });

    csvExporter.generateCsv(dataForCSV);
  };

  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <ListLayout.TopBarTitle>
          {I18n.t("js.carbon.admin_carbon_fields_title")}
        </ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <ListLayout.Header>
          <ListLayout.Filters>
            <FieldsFilters
              {...filters}
              onChange={(...args) => {
                filters.onChange(...args);
                resetPagination();
              }}
              onClear={() => {
                filters.onClear();
                resetPagination();
              }}
            />
          </ListLayout.Filters>

          <ListLayout.Actions>
            <FieldsActions
              bulkActionAccess={bulkActionAccess}
              hasSelection={Object.keys(selection).length > 0}
              onBulkDeleteFields={showDeleteConfirmationDialog}
              onBulkUnlockDefinitions={bulkUnlockDefinitions}
              onBulkUnlockActuals={bulkUnlockActuals}
              onDownloadCSV={handleExportToCSV}
              onBulkEditFieldNames={showBulkEditFieldNamesModal}
              onBulkExpireFields={bulkExpireFields}
              onBulkUnexpireFields={bulkUnexpireFields}
            />
          </ListLayout.Actions>
        </ListLayout.Header>

        <Table
          instance={table}
          meta={fieldsData?.meta}
          pagination={pagination}
          isLoading={isLoading}
          onPaginationChange={handlePaginationChanged}
        />
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default Fields;
