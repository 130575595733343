import { useState } from "react";

import { Icon } from "@ag/design-system/assets";
import { Button, FileRejection, InfoBox } from "@ag/design-system/atoms";
import { Modal } from "@ag/design-system/organisms";
import { DropzoneField } from "@ag/form-fields";
import { ToastNotification } from "@ag/utils/services";

import {
  useCreatePresignedUrlMutation,
  useUploadInventoryFileMutation,
} from "~features/inventory";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const InventoryImport = ({ isOpen, onClose }: Props) => {
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    console.warn(e.target);
  };
  const [fileErrors, setFileErrors] = useState<
    { code: string; message: string }[] | undefined
  >();
  const [file, setFile] = useState<File | undefined>();
  const createPresignedUrl = useCreatePresignedUrlMutation();
  const uploadInventoryFile = useUploadInventoryFileMutation();

  const handleFileRejection = (fileRejection: FileRejection) => {
    const errors = fileRejection.errors.map(({ code, message }) => {
      switch (code) {
        case "file-too-large":
          return {
            code,
            message:
              "File is too large, please upload a file smaller than 500KB",
          };
        case "file-invalid-type":
          return {
            code,
            message: "Invalid file type, please upload a .csv or .xlsx file",
          };
        default:
          return { code, message };
      }
    });

    setFileErrors(errors);
  };

  const handleFileUploadSuccess = () => {
    ToastNotification.success("File uploaded");
    onClose();
  };

  const handleFileAccepted = (acceptedFile: File | undefined) => {
    if (!acceptedFile) ToastNotification.error("No file uploaded");
    else {
      setFile(acceptedFile);
      createPresignedUrl.mutate(
        { filename: acceptedFile.name },
        {
          onSuccess: ({ signedUrl }) => {
            uploadInventoryFile.mutate(
              { file: acceptedFile, signedUrl },
              { onSuccess: handleFileUploadSuccess },
            );
          },
        },
      );
    }
  };

  const handleFileDropped = (
    acceptedFile: File | undefined,
    fileRejection: FileRejection | undefined,
  ) => {
    setFileErrors(undefined);

    if (fileRejection) {
      handleFileRejection(fileRejection);
    } else {
      handleFileAccepted(acceptedFile);
    }
  };

  return (
    <Modal.Root isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Header>
        <Modal.Title>Import Inventory</Modal.Title>
      </Modal.Header>

      <Modal.Content>
        <div className="flex flex-1 flex-col">
          <p className="text-p1 font-h3">Select the inventory file:</p>

          {fileErrors && (
            <div className="grid w-full max-w-screen-sm gap-1 text-start">
              <h3 className="mb-2 text-h3">Errors:</h3>
              {fileErrors.map(fileError => (
                <InfoBox
                  key={fileError.code}
                  variant="danger"
                  icon="triangle-exclamation"
                >
                  {fileError.message}
                </InfoBox>
              ))}
            </div>
          )}

          <form onSubmit={onSubmit}>
            <DropzoneField
              onChange={handleFileDropped}
              label=""
              acceptType={[".csv", ".xlsx"]}
              maxSize={5000 * 1024} // max 500MB
              isMultiple={false}
              value={file}
            >
              <div
                key="content"
                className="grid justify-items-center gap-6 py-8"
              >
                <Icon
                  name="chart-network"
                  fontSize={32}
                  className="self-center"
                />

                <div className="grid gap-2">
                  <p className="text-p1">Drag and drop inventory file</p>
                  <p className="text-p1">.csv, .xsls max 500MB</p>
                </div>

                <span className="">or</span>

                <Button type="button" size="small" variant="text">
                  Select from folder
                </Button>
              </div>
            </DropzoneField>
          </form>
        </div>
      </Modal.Content>
    </Modal.Root>
  );
};

export default InventoryImport;
