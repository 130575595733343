import { AuthorizedSidebar } from "~features/navigation";
import { withFeatureFlagAccess } from "~hocs/withFeatureFlagAccess";
import DetailsLayout from "~layouts/details-layout";

const FertiliserDetails = () => {
  return (
    <DetailsLayout.Root>
      <DetailsLayout.Sidebar>
        <AuthorizedSidebar />
      </DetailsLayout.Sidebar>

      <DetailsLayout.TopBar>
        <DetailsLayout.TopBarTitle>
          Fertiliser details
        </DetailsLayout.TopBarTitle>
      </DetailsLayout.TopBar>

      <DetailsLayout.Content>
        <div />
      </DetailsLayout.Content>
    </DetailsLayout.Root>
  );
};

export default withFeatureFlagAccess("fertilisers-v2", {
  redirectPath: "/carbon/fertilisers",
})(FertiliserDetails);
