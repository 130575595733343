import "src/tokens/colors.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/tokens/colors.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA4WX3W4jKRCF7+cpIu21peYfZq4KqngPb8abjcZjR06i0Wi1775qFySmAa+58MV3uptzuoDqr5fz+e3hny8PD7vd4/l4vuyeLofDaSeW5evDHwlIJ/jWUXmlwSUXUk/VlVoDzoue6itV0is7uNZc6bKYZEJPbaXaDO7sCtWgfU99oSor6mmoVCl5S88v+2MJA4kyxQ5yFimRJdFBjiImRHQd5CQgokrYQQ4iQFqi7yDn4ENUAB3kGJwLGHorvrwb71xvhUMwygmrb+HbvhZEDlm1GayMI8iKXOtyZZwAJYypuycHQCblKLeM/SMmBcuWsf0kIfhuLuweXFic3TI275Nztnsee3fCkG7K48/j+6GYj4RA2EF2D3otgQ6yfR8jYe4g+3cqLm1uV8gBGACZ+is5AW19iP2VHIHSbgn9bDkDaYxvF+cVcgjCq9wGdL7sT081hpxpSWaASxlklBAGWBWcjMcB1gXH4PQAm4KBTBxgDoSkz+3rK5gjSdYJLQaYQ4FgrBoZC6VuFMlmuf0+HI/nXx+xZEA1wDWWrMENcI2FsF2RBddYyJrRs2ssCFIPMMeCMToxuppjiS4YIQeYYwnKmWU0tbJkwNDSFP7l8P0jE0y0bFkNBALQltU0/GbzWlmNwmnntqzmYGJbHiurIWjfnhorq4WhhenuWatCGaO2rJaEBL09xX5X824dHWT3lNfRQbaPeh0d1GXbW0cHOYC4rKOD5bzAdXSQI7Cwjg76stGso4McglTruIW//n5++9wt1l+70+wff3wcuOvvln7fv+13L++Xl2O9AyHF9g63Go4TAe1cw6kmE2muKYcxgpxrypksvZhrStTKKdIzTTmhhdXJzjQcvElGgphpOEMtFDjZaTZ7djZ5qqkLkmAw583ujaM5b7fwOJjzZh8POJ+zrQs9DGqj2dFJ2zTX+LK4Dc01nCEIjQPNbS9MLuc8k5Qq1DmTmkhKKwg540zC+YWYIeJEwvE5Q6LtvG8ktlROzA4mEg5P5JDtbC6cnTAO9WwuZQmjXVQ/l5fn04/PhgHyoIhXRSk9QDcI7qoohWeAkhsrStkt3kAcKzg1iib5wcJdFeWMQKXMxEs5KYIweuKFI4tyEbKP7Koop4ZeFtnf46bTJJVhsMXctJtpyWqmqD0n+UxjRWk8BfpBRd90nxqSHbyXmxZUiahw4qV8j+UQYhgr6jeZdW7ipR4TmmzzlJ+H19f90/PpaXe4XM6XWmlEkuQ9Xd3GIEG6pyvblHfC3X1u6aCCSOLu/cqbp+1x96n7tb+c1v+PDTvQ/yg/O8e2z++VxU2Mue0SeyX7weBc29P1SnZkrUxLGCufT3+d67ezxJDvydiLS2Bo4uUqKx9F6A35O7LyBURWt9W7kZXiAiVxYvb1/fHx8Ppal6XIYlZeVclGwJJsm9teWbz4BH5SYlVZ7PiAZlJkVVk+4Iy3Cr99+fc/qB8std4RAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/tokens/elevation.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/tokens/elevation.css.ts.vanilla.css\",\"source\":\"OnJvb3QgewogIC0tXzFnbW1heDYwOiAwIDRweCA4cHggcmdiYSgwLDAsMCwwLjA1KTsKICAtLV8xZ21tYXg2MTogMCA4cHggOHB4IHJnYmEoMCwwLDAsMC4xKTsKfQ==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/tokens/radius.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/tokens/radius.css.ts.vanilla.css\",\"source\":\"OnJvb3QgewogIC0tcmFkaXVzLTEwMDogNHB4OwogIC0tcmFkaXVzLTIwMDogOHB4Owp9\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/tokens/spacing.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/tokens/spacing.css.ts.vanilla.css\",\"source\":\"OnJvb3QgewogIC0tXzEzYTYxd3QwOiA0cHg7CiAgLS1fMTNhNjF3dDE6IDhweDsKICAtLV8xM2E2MXd0MjogMTZweDsKICAtLV8xM2E2MXd0MzogMjRweDsKICAtLV8xM2E2MXd0NDogMzJweDsKICAtLV8xM2E2MXd0NTogNDBweDsKfQ==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/tokens/typography.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/tokens/typography.css.ts.vanilla.css\",\"source\":\"Ll83YnVxdGIwIHsKICBmb250LXNpemU6IDMycHg7CiAgbGluZS1oZWlnaHQ6IDQ4cHg7CiAgZm9udC13ZWlnaHQ6IDcwMDsKfQouXzdidXF0YjEgewogIGZvbnQtc2l6ZTogMjRweDsKICBsaW5lLWhlaWdodDogMzZweDsKICBmb250LXdlaWdodDogNzAwOwp9Ci5fN2J1cXRiMiB7CiAgZm9udC1zaXplOiAyMHB4OwogIGxpbmUtaGVpZ2h0OiAzMHB4OwogIGZvbnQtd2VpZ2h0OiA3MDA7Cn0KLl83YnVxdGIzIHsKICBmb250LXNpemU6IDE2cHg7CiAgbGluZS1oZWlnaHQ6IDI0cHg7CiAgZm9udC13ZWlnaHQ6IDcwMDsKfQouXzdidXF0YjQgewogIGZvbnQtc2l6ZTogMTRweDsKICBsaW5lLWhlaWdodDogMjBweDsKICBmb250LXdlaWdodDogNzAwOwp9Ci5fN2J1cXRiNSB7CiAgZm9udC1zaXplOiAxMnB4OwogIGxpbmUtaGVpZ2h0OiAyMHB4OwogIGZvbnQtd2VpZ2h0OiA3MDA7Cn0KLl83YnVxdGI2IHsKICBmb250LXNpemU6IDExcHg7CiAgbGluZS1oZWlnaHQ6IDE2cHg7CiAgZm9udC13ZWlnaHQ6IDcwMDsKfQouXzdidXF0YjcgewogIGZvbnQtc2l6ZTogMTBweDsKICBsaW5lLWhlaWdodDogMTRweDsKICBmb250LXdlaWdodDogNzAwOwp9Ci5fN2J1cXRiOCB7CiAgZm9udC1zaXplOiAxNnB4OwogIGxpbmUtaGVpZ2h0OiAyNHB4OwogIGZvbnQtd2VpZ2h0OiA0MDA7Cn0KLl83YnVxdGI5IHsKICBmb250LXNpemU6IDE0cHg7CiAgbGluZS1oZWlnaHQ6IDIwcHg7CiAgZm9udC13ZWlnaHQ6IDQwMDsKfQouXzdidXF0YmEgewogIGZvbnQtc2l6ZTogMTJweDsKICBsaW5lLWhlaWdodDogMjBweDsKICBmb250LXdlaWdodDogNDAwOwp9Ci5fN2J1cXRiYiB7CiAgZm9udC1zaXplOiAxNHB4OwogIGxpbmUtaGVpZ2h0OiAyMHB4OwogIGZvbnQtd2VpZ2h0OiA2MDA7Cn0=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/tokens/animations.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/tokens/animations.css.ts.vanilla.css\",\"source\":\"QGtleWZyYW1lcyBfMXNybzl5NzAgewogIGZyb20gewogICAgdHJhbnNmb3JtOiB0cmFuc2xhdGVYKC0xMDAlKTsKICB9Cn0KQGtleWZyYW1lcyBfMXNybzl5NzEgewogIHRvIHsKICAgIHRyYW5zZm9ybTogdHJhbnNsYXRlWCgtMTAwJSk7CiAgfQp9CkBrZXlmcmFtZXMgXzFzcm85eTcyIHsKICBmcm9tIHsKICAgIHRyYW5zZm9ybTogdHJhbnNsYXRlWCgxMDAlKTsKICB9Cn0KQGtleWZyYW1lcyBfMXNybzl5NzMgewogIHRvIHsKICAgIHRyYW5zZm9ybTogdHJhbnNsYXRlWCgxMDAlKTsKICB9Cn0KQGtleWZyYW1lcyBfMXNybzl5NzQgewogIGZyb20gewogICAgb3BhY2l0eTogMDsKICB9CiAgdG8gewogICAgb3BhY2l0eTogMTsKICB9Cn0KQGtleWZyYW1lcyBfMXNybzl5NzUgewogIGZyb20gewogICAgb3BhY2l0eTogMTsKICB9CiAgdG8gewogICAgb3BhY2l0eTogMDsKICB9Cn0=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/pages/certificates-management/import-vcu/upload-documentation.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/pages/certificates-management/import-vcu/upload-documentation.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5WSTW6DMBCF95xiNpXahSMDaRs5h6kcPAG3xkb2JARVvXuFaQskIVVWljw/75uZt3pL03rTIefwmQAoHRojOwF7g6dtAvFlSnssSDsroHDmUNs+UspGQJ41Me39EEjvO1Y4S2hpqGeBpKc+LI0uLdOEdRBQoCX0/XcjldK2ZOQmrVqtqBKQcv6wTb6S1S9geh3wHuV5aHm0nSw+Su8OVgk4Sv/IWOGM86z02LGU86cZWBbBRmw+zFFryyrUZUUCsix+ToryWBR3uB7y71j9xczjSq9ueqK7jrq19KW2bOeIXC1gs0AQ8TYLFx41l0H/o3m+13S3jfQHS17aoIfi8ZjDFYGv8gAoA85RROWO6CPQeYmAttI0z3+5jn5zZaGRBbIdUotoJ17PL+zxGrv/iF9Y8DVaEKAH3hvXCqi0UkNLwhOxMYDG6CboENX6IViEEGBd62UTjSpP7AzkG8s03HoWBAAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var container = '_11m8ye00';
export var detailsBox = '_11m8ye01';
export var documentationUploadedRow = '_11m8ye05';
export var documentationUploadedRowContent = '_11m8ye06';
export var documentationUploadedRowContentText = '_11m8ye07';
export var dropzoneBox = '_11m8ye02';
export var dropzoneBoxContent = '_11m8ye03';
export var uploadSuccessBox = '_11m8ye04';