import { useCallback, useState } from "react";
import { useDebounce } from "rooks";

import { IconButton, Input } from "@ag/design-system/atoms";
import { MultiSelect } from "@ag/design-system/molecules";
import { colorsObject } from "@ag/design-system/tokens";
import { getSVGFromCoordinates } from "@ag/map/helpers";
import { GeometryType } from "@ag/map/types";

import { IssueSeverityFlagTypes } from "../entities/field-with-all-boundaries";
import { BoundaryMapField } from "../types/boundary-map-field";
import { FlagCountBadge } from "./flag-count-badge";

export const FieldListPanel = ({
  fields,
  onClose,
  onFieldCardClick,
}: {
  fields: BoundaryMapField[];
  onClose: () => void;
  onFieldCardClick: (field: BoundaryMapField) => void;
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFlagTypes, setSelectedFlagTypes] = useState<string[] | null>(
    [],
  );
  const [filteredFields, setFilteredFields] = useState(fields);

  const debouncedFieldIdSearch = useDebounce((query: string) => {
    filterFields(query, selectedFlagTypes);
  }, 300);

  const handleFieldIdSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const query = event.target.value;
    setSearchQuery(query);
    debouncedFieldIdSearch(query);
  };

  const handleFlagFilterSelectionChange = (filterValues: string[] | null) => {
    setSelectedFlagTypes(filterValues);
    filterFields(searchQuery, filterValues);
  };

  const filterFields = useCallback(
    (query: string, flagFilters: string[] | null) => {
      const lowerCaseQuery = query.toLowerCase();

      const newFilteredFields = fields.filter(field => {
        const matchesQuery = field.id.toLowerCase().includes(lowerCaseQuery);
        const matchesFlag =
          flagFilters && flagFilters.length > 0
            ? (flagFilters.includes("medium-severity") &&
                (field.checkResults?.overlap?.result.some(
                  overlap => overlap.flag === IssueSeverityFlagTypes.YELLOW,
                ) ||
                  (field.sizeHa > 4 &&
                    field.checkResults?.iou?.flag ===
                      IssueSeverityFlagTypes.YELLOW))) ||
              (flagFilters.includes("high-severity") &&
                (field.checkResults?.overlap?.result.some(
                  overlap => overlap.flag === IssueSeverityFlagTypes.RED,
                ) ||
                  (field.sizeHa > 4 &&
                    field.checkResults?.iou?.flag ===
                      IssueSeverityFlagTypes.RED)))
            : true;

        return matchesQuery && matchesFlag;
      });

      setFilteredFields(newFilteredFields);
    },
    [fields],
  );

  return (
    <div className="flex h-full w-[360px] flex-col gap-4 rounded-lg bg-white-100 p-4 px-3 pb-3 pr-0">
      <div className="flex flex-row items-start justify-between pr-4">
        <p className="text-h3">Field list ({filteredFields.length})</p>
        <IconButton onClick={onClose} variant="text" icon="close" />
      </div>

      <div className="flex w-full flex-row gap-2 pr-3">
        <Input
          placeholder="Search field ID"
          value={searchQuery}
          onChange={handleFieldIdSearchChange}
        />
        <MultiSelect.Root
          ariaLabel="Flag type filter"
          values={selectedFlagTypes}
          placeholder="Filter"
          renderSelection={selection => `${selection.length} selected`}
          variant="full-width"
          onChange={handleFlagFilterSelectionChange}
        >
          <MultiSelect.Item key="high-severity" textValue="Severity: high">
            <p className="text-h5 text-messaging-error-700">Severity: high</p>
          </MultiSelect.Item>

          <MultiSelect.Item key="medium-severity" textValue="Severity: medium">
            <p className="text-h5 text-messaging-warning-700">
              Severity: medium
            </p>
          </MultiSelect.Item>
        </MultiSelect.Root>
      </div>

      <div className="max-h-full flex-grow overflow-y-scroll pr-3">
        <div className="flex flex-col gap-4">
          {filteredFields.map(field => {
            const pathData =
              (field.geometry?.type === GeometryType.Polygon ||
                field.geometry?.type === GeometryType.MultiPolygon) &&
              field.geometry?.coordinates &&
              field.bbox &&
              getSVGFromCoordinates(
                field.geometry.type,
                field.geometry?.coordinates,
                field.bbox,
                28,
                28,
              );

            return (
              <div
                key={field.id}
                className="flex w-full cursor-pointer flex-row items-center justify-between gap-2 rounded-lg border border-green-100 p-4 shadow-[0_4px_8px_0_rgba(0,0,0,0.05)]"
                onClick={() => onFieldCardClick(field)}
              >
                <div className="flex flex-row items-center gap-3">
                  {/* Older fields had only the centrepoint latLon stored with no boundaries */}
                  {pathData ? (
                    <svg width="28" height="32" viewBox="0 0 28 28">
                      <path
                        d={pathData}
                        stroke={colorsObject.data.blue[400]}
                        fill={colorsObject.data.blue[100]}
                        strokeWidth="1"
                      />
                    </svg>
                  ) : (
                    <div className="w-7" />
                  )}

                  <p className="text-h5">{field.id}</p>
                </div>
                <div className="flex flex-row items-center gap-2">
                  {field.totalIssues > 0 && (
                    <FlagCountBadge
                      variant={field.issueSeverityFlag as "red" | "yellow"}
                      countValue={field.totalIssues}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
