import { forwardRef } from "react";
import { FieldError } from "react-hook-form";

import { Input } from "@ag/design-system/atoms";
import { FormField } from "@ag/design-system/organisms";

type OwnProps = {
  label?: string;
  description?: string;
  rightAddon?: React.ReactNode;
  leftAddon?: React.ReactNode;
  placeholder?: string;
  tooltip?: string;
  error?: FieldError;
  isDisabled?: boolean;
  isInvalid?: boolean;
  isRequired?: boolean;
  isDescriptionIconVisible?: boolean;
};

export type Props = React.InputHTMLAttributes<HTMLInputElement> & OwnProps;

export const InputField = forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      name,
      label,
      description,
      tooltip,
      type = "text",
      placeholder,
      rightAddon,
      leftAddon,
      error,
      isDisabled = false,
      isInvalid = false,
      isRequired = false,
      isDescriptionIconVisible = true,
      ...rest
    },
    ref,
  ) => {
    const labelId = id || name;
    return (
      <FormField.Root type={type === "hidden" ? "hidden" : undefined}>
        {label && (
          <FormField.Label tooltip={tooltip} as="label" id={labelId}>
            {label}
            {isRequired && " *"}
          </FormField.Label>
        )}

        <Input
          {...rest}
          aria-labelledby={labelId}
          name={name}
          ref={ref}
          type={type}
          placeholder={placeholder}
          rightAddon={rightAddon}
          leftAddon={leftAddon}
          disabled={isDisabled}
          isInvalid={isInvalid || Boolean(error)}
        />

        {error && <FormField.Error>{error.message}</FormField.Error>}

        {!error && description && (
          <FormField.Description isIconVisible={isDescriptionIconVisible}>
            {description}
          </FormField.Description>
        )}
      </FormField.Root>
    );
  },
);

InputField.displayName = "InputField";
