import { SortingState } from "@tanstack/react-table";
import { useState } from "react";

import { Button } from "@ag/design-system/atoms";
import { Tabs, parseSorting } from "@ag/design-system/organisms";
import { usePagination } from "@ag/utils/hooks";

import {
  ActiveInventoryImportsTable,
  DiscardedInventoryImportsTable,
  InventoryImportModal,
  useInventoryImportsQuery,
} from "~features/inventory";
import { ImportStatus } from "~features/inventory/entities/inventory-import";
import { AuthorizedSidebar } from "~features/navigation";
import {
  AuthResourceClass,
  Page,
  useAuthPermissions,
  withPageAccess,
} from "~features/permission";
import ListLayout from "~layouts/list-layout";

const InventoryImports = () => {
  const [pagination, _, resetPagination] = usePagination();
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  const [activeTab, setActiveTab] = useState<"active" | "discarded">("active");
  const [sorting, setSorting] = useState<SortingState>([
    { id: "createdAt", desc: true },
  ]);

  const handleTabChange = (value: string) => {
    resetPagination();
    setSorting([{ id: "createdAt", desc: true }]);
    setActiveTab(value as "active" | "discarded");
  };

  const { data: authPermissions } = useAuthPermissions();
  const hasAdminAccess = authPermissions?.read?.includes(
    AuthResourceClass.Admin,
  );

  const active = useInventoryImportsQuery({
    ...pagination,
    sort: parseSorting(sorting),
    filters: {
      status: [
        ImportStatus.UPLOADING,
        ImportStatus.UPLOADED,
        ImportStatus.PROCESSING,
        ImportStatus.PROCESSED,
        ImportStatus.FAILED,
      ],
    },
  });
  const discarded = useInventoryImportsQuery({
    ...pagination,
    sort: parseSorting(sorting),
    filters: { status: [ImportStatus.DISCARDED] },
  });

  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <ListLayout.TopBarTitle>Inventory Imports</ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />
        {/* TODO => Filters */}
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <ListLayout.Header variant="slim">
          <ListLayout.Actions>
            <Button onClick={() => setIsImportModalOpen(true)}>
              Start new import
            </Button>
          </ListLayout.Actions>
        </ListLayout.Header>

        <InventoryImportModal
          isOpen={isImportModalOpen}
          onClose={() => setIsImportModalOpen(false)}
        />

        <div className="px-6">
          <Tabs.Root value={activeTab} onChange={handleTabChange}>
            <Tabs.List>
              <Tabs.Trigger value="active">
                <Tabs.TriggerTitle>Active</Tabs.TriggerTitle>
                <Tabs.TriggerBadge
                  maxValue={9999}
                  value={active.data?.meta.totalItems}
                />
              </Tabs.Trigger>

              <Tabs.Trigger value="discarded">
                <Tabs.TriggerTitle>Discarded</Tabs.TriggerTitle>
                <Tabs.TriggerBadge
                  maxValue={9999}
                  value={discarded.data?.meta.totalItems}
                />
              </Tabs.Trigger>
            </Tabs.List>

            <Tabs.Content value="active">
              <ActiveInventoryImportsTable
                {...active.data}
                sorting={{ sorting, setSorting }}
                isLoading={active.isLoading}
                hasAdminAccess={hasAdminAccess}
                handleImportModalOpen={() => setIsImportModalOpen(true)}
              />
            </Tabs.Content>

            <Tabs.Content value="discarded">
              <DiscardedInventoryImportsTable
                {...discarded.data}
                sorting={{ sorting, setSorting }}
                isLoading={discarded.isLoading}
                hasAdminAccess={hasAdminAccess}
              />
            </Tabs.Content>
          </Tabs.Root>
        </div>
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default withPageAccess(Page.InventoryImports)(InventoryImports);
