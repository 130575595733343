import { cva } from "class-variance-authority";
import { createRef, forwardRef, useEffect } from "react";
import { mergeRefs } from "react-merge-refs";

import { cn } from "~utils";

const radioButtonVariants = cva(
  "relative m-0 h-4 w-4 appearance-none rounded-full border border-grey-700 bg-white-100 after:absolute after:left-1/2 after:top-1/2 after:block after:h-[6px] after:w-[6px] after:-translate-x-2/4 after:-translate-y-2/4 after:rounded-full after:bg-white-100 checked:border-green-400 checked:bg-green-400 invalid:border-red-600 invalid:bg-red-600 disabled:border-grey-200 disabled:bg-grey-200 disabled:after:bg-grey-700",
  {
    variants: {
      isInvalid: {
        true: "border-red-600 bg-red-600",
        false: "",
      },
    },
  },
);

type OwnProps = {
  isInvalid?: boolean;
};

type Props = OwnProps &
  Omit<React.InputHTMLAttributes<HTMLInputElement>, "type">;

export const RadioButton = forwardRef<HTMLInputElement, Props>(
  ({ isInvalid, ...rest }: Props, ref) => {
    const inputRef = createRef<HTMLInputElement>();

    useEffect(() => {
      inputRef.current?.setCustomValidity(isInvalid ? "error" : "");
    }, [inputRef, isInvalid]);

    return (
      <input
        ref={mergeRefs([ref, inputRef])}
        type="radio"
        className={cn(
          radioButtonVariants({
            isInvalid: Boolean(isInvalid),
          }),
        )}
        {...rest}
      />
    );
  },
);

RadioButton.displayName = "RadioButton";
