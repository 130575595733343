import { zodResolver } from "@hookform/resolvers/zod";
import { SortingState } from "@tanstack/react-table";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  StringParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import { transformInitialFilterValues } from "@ag/components/Filters";
import { Select } from "@ag/design-system/molecules";
import { parseSorting } from "@ag/design-system/organisms";
import { InputField, SelectField } from "@ag/form-fields";
import { usePagination } from "@ag/utils/hooks";

import { Filters, useSearchParamForm } from "~components/filters";
import Table from "~components/table";
import {
  BuyoutAgreementsFilters,
  BuyoutContractStatus,
  BuyoutAgreementsFiltersSchema as schema,
  useBuyoutAgreementsQuery,
  useBuyoutAgreementsTable,
} from "~features/buyout-agreements";
import { AuthorizedSidebar } from "~features/navigation";
import {
  UserManagementResourceClass,
  useUserManagementPermissions,
} from "~features/permission";
import { transformEnumToLabels, transformedLabelValue } from "~helpers";
import ListLayout from "~layouts/list-layout";

const BuyoutAgreementsList = () => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([
    { id: "id", desc: true },
  ]);

  const [pagination, updatePagination, resetPagination] = usePagination();
  const { data: userManagementPermissions } = useUserManagementPermissions();

  const [query, setQuery] = useQueryParams({
    userId: withDefault(StringParam, undefined),
    status: withDefault(
      createEnumParam(Object.values(BuyoutContractStatus)),
      undefined,
    ),
    year: withDefault(StringParam, undefined),
  });

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<BuyoutAgreementsFilters>({
    values: transformInitialFilterValues(query),
    resolver: zodResolver(schema),
  });

  const {
    handleClearFiltersBar,
    handleClearFiltersDrawer,
    handleSubmitFilters,
  } = useSearchParamForm<BuyoutAgreementsFilters>(
    query,
    setQuery,
    resetPagination,
    () => setIsFiltersOpen(false),
  );

  const { data, isLoading } = useBuyoutAgreementsQuery({
    ...pagination,
    filters: {
      ...query,
    },
    sort: parseSorting(sorting),
  });

  const contractStatusLabels = transformEnumToLabels(BuyoutContractStatus);

  const renderFilterBarItem = (
    key: keyof BuyoutAgreementsFilters,
    value: BuyoutAgreementsFilters[keyof BuyoutAgreementsFilters],
  ) => {
    const label = (
      {
        userId: "User ID",
        status: "Contract Status",
        year: "Year",
      } as Record<keyof BuyoutAgreementsFilters, string>
    )[key];

    return `${label}: ${transformedLabelValue(value)}`;
  };

  const canViewUser = userManagementPermissions?.read?.includes(
    UserManagementResourceClass.User,
  );

  const table = useBuyoutAgreementsTable(
    data?.items,
    { sorting, setSorting },
    Boolean(canViewUser),
  );

  const handleFormFiltersClear = () => {
    reset();
    handleClearFiltersDrawer();
  };

  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <ListLayout.TopBarTitle>Buyout Agreements</ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />

        <Filters.Drawer
          isOpen={isFiltersOpen}
          onSubmit={handleSubmit(handleSubmitFilters)}
          onClose={() => setIsFiltersOpen(false)}
          onClear={handleFormFiltersClear}
        >
          <InputField {...register("userId")} label="User ID" type="number" />

          <Controller
            name="status"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                {...fieldState}
                value={field.value ?? ""}
                label="Contract Status"
                optionsClassName="z-modal"
              >
                <Select.OptionAll>All</Select.OptionAll>

                {Object.values(BuyoutContractStatus).map(type => (
                  <Select.Option key={type} value={type}>
                    {contractStatusLabels[type]}
                  </Select.Option>
                ))}
              </SelectField>
            )}
          />

          <InputField
            {...register("year")}
            label="Year"
            type="number"
            error={errors.year}
          />
        </Filters.Drawer>
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <ListLayout.Header>
          <Filters.Bar
            values={query}
            renderItem={renderFilterBarItem}
            onToggleOpen={() => setIsFiltersOpen(value => !value)}
            onClear={handleClearFiltersBar}
          />
        </ListLayout.Header>

        <Table
          instance={table}
          meta={data?.meta}
          pagination={pagination}
          isLoading={isLoading}
          onPaginationChange={updatePagination}
        />
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default BuyoutAgreementsList;
