import { useEffect, useState } from "react";

import { Button, Chip, InfoBox } from "@ag/design-system/atoms";
import { Select } from "@ag/design-system/molecules";
import { Tabs, useTabs } from "@ag/design-system/organisms";
import { GeoJSONGeometry } from "@ag/map/types";

import { useAllFieldBoundariesQuery } from "~features/field";
import { FieldBoundariesTypes } from "~features/field/entities/field-boundaries";

import {
  CheckResults,
  IssueSeverityFlagTypes,
} from "../entities/field-with-all-boundaries";
import { BoundaryMapField } from "../types/boundary-map-field";
import { CheckResultInfoCard } from "./check-result-info-card";
import { FieldSizeData, FieldSizeTable } from "./field-size-table";

export const FieldInfoPanel = ({
  selectedField,
  checkResults,
  isEditModeOn,
  isLoading,
  onMRVBoundariesVisibilityChange,
  onFarmerBoundariesVisibilityChange,
  onBoundaryEdit,
  onBoundarySave,
  onEditDiscard,
  onSetMrvAsActive,
  onMapCentre,
  onBackClick,
}: {
  selectedField: BoundaryMapField;
  checkResults?: CheckResults;
  isEditModeOn: boolean;
  isLoading: boolean;
  onMRVBoundariesVisibilityChange: (visible: boolean) => void;
  onFarmerBoundariesVisibilityChange: (visibile: boolean) => void;
  onBoundaryEdit: () => void;
  onBoundarySave: () => void;
  onEditDiscard: () => void;
  onSetMrvAsActive: (mrvBoundary: GeoJSONGeometry) => void;
  onMapCentre: () => void;
  onBackClick: () => void;
}) => {
  const [fieldSizeData, setFieldSizeData] = useState<FieldSizeData>({
    activeBoundarySize: undefined,
    farmerBoundarySize: undefined,
    mrvBoundarySize: undefined,
  });

  const { data: allBoundariesForField } = useAllFieldBoundariesQuery(
    selectedField.id,
  );
  const mrvBoundary = allBoundariesForField?.find(
    boundary => boundary.type === FieldBoundariesTypes.MRV,
  );

  const { activeTab, onChange: onTabChange } = useTabs({
    initialValue: "size",
  });

  const [visibleBoundaries, setVisibleBoundaries] = useState<
    "activeOnly" | "activeAndFarmer" | "activeAndMrv"
  >("activeOnly");

  useEffect(() => {
    if (visibleBoundaries === "activeOnly") {
      onMRVBoundariesVisibilityChange(false);
      onFarmerBoundariesVisibilityChange(false);
    } else if (visibleBoundaries === "activeAndFarmer") {
      onMRVBoundariesVisibilityChange(false);
      onFarmerBoundariesVisibilityChange(true);
    } else if (visibleBoundaries === "activeAndMrv") {
      onMRVBoundariesVisibilityChange(true);
      onFarmerBoundariesVisibilityChange(false);
    }
  }, [
    visibleBoundaries,
    onMRVBoundariesVisibilityChange,
    onFarmerBoundariesVisibilityChange,
  ]);

  useEffect(() => {
    if (allBoundariesForField) {
      const activeBoundary = allBoundariesForField.find(
        boundary => boundary.isActive,
      );
      const farmerBoundary = allBoundariesForField.find(
        boundary => boundary.type === FieldBoundariesTypes.FARMER,
      );
      const mrvBoundary = allBoundariesForField.find(
        boundary => boundary.type === FieldBoundariesTypes.MRV,
      );

      setFieldSizeData({
        activeBoundarySize: activeBoundary?.sizeHa,
        farmerBoundarySize: farmerBoundary?.sizeHa,
        mrvBoundarySize: mrvBoundary?.sizeHa,
      });
    }
  }, [allBoundariesForField]);

  return (
    <div className="w-[360px]">
      <div className="flex h-[700px] flex-col justify-between gap-4 rounded-lg bg-white-100 p-4">
        <div className="flex flex-col items-start gap-5">
          <Button onClick={onBackClick} variant="text" icon="chevron-left">
            Back
          </Button>

          <p className="text-h3">Field id: {selectedField.id}</p>

          <Chip variant="neutral"> HY {selectedField.harvestYear}</Chip>

          <Button onClick={onMapCentre} variant="text" icon="crosshairs">
            Centre on map
          </Button>

          <Select.Root
            className="w-full "
            value={visibleBoundaries}
            onChange={value => {
              setVisibleBoundaries(
                value as "activeOnly" | "activeAndFarmer" | "activeAndMrv",
              );
            }}
          >
            <Select.Option value="activeOnly">Active boundary</Select.Option>
            <Select.Option value="activeAndMrv">
              Active + MRV boundary
            </Select.Option>
            <Select.Option value="activeAndFarmer">
              Active + Farmer boundary
            </Select.Option>
          </Select.Root>

          <div className="w-full">
            <Tabs.Root value={activeTab} onChange={onTabChange}>
              <Tabs.List key="details">
                <Tabs.Trigger value="size">
                  <Tabs.TriggerTitle>Field size</Tabs.TriggerTitle>
                </Tabs.Trigger>

                <Tabs.Trigger value="info">
                  <Tabs.TriggerTitle>Info</Tabs.TriggerTitle>
                </Tabs.Trigger>

                <Tabs.Trigger value="changelog">
                  <Tabs.TriggerTitle>Changelog</Tabs.TriggerTitle>
                </Tabs.Trigger>
              </Tabs.List>

              <Tabs.Content key="size" value="size">
                <div className="flex flex-col gap-2">
                  {checkResults &&
                    checkResults.overlap &&
                    checkResults.overlap?.result.length > 0 &&
                    checkResults.overlap?.result
                      .filter(
                        overlapCheckResult =>
                          overlapCheckResult.flag !==
                          IssueSeverityFlagTypes.NONE,
                      )
                      .map(overlapCheckResult => (
                        <CheckResultInfoCard
                          overlapCheckResult={overlapCheckResult}
                          key={`check-result-${selectedField.id}-${overlapCheckResult.overlappingFieldId}`}
                        />
                      ))}

                  {checkResults?.iou &&
                    selectedField.sizeHa > 4 &&
                    (checkResults.iou.flag === IssueSeverityFlagTypes.RED ||
                      checkResults.iou.flag ===
                        IssueSeverityFlagTypes.YELLOW) && (
                      <CheckResultInfoCard
                        IOUCheckResult={checkResults.iou}
                        key={`check-result-${selectedField.id}-${checkResults.iou.carbonFieldId}`}
                      />
                    )}
                </div>

                <FieldSizeTable fieldSizeData={fieldSizeData} />
              </Tabs.Content>

              <Tabs.Content key="info" value="info">
                Info
              </Tabs.Content>

              <Tabs.Content key="changelog" value="changelog">
                Changelog
              </Tabs.Content>

              <Tabs.Content key="cover" value="cover">
                <div className="rounded-lg bg-white-100 p-6">
                  Cover crops content
                </div>
              </Tabs.Content>
            </Tabs.Root>
          </div>
        </div>

        <div className="flex w-full flex-col gap-3">
          {isEditModeOn ? (
            <>
              <Button
                onClick={onEditDiscard}
                variant="secondary"
                className="w-full"
                isDanger
              >
                Discard changes
              </Button>
              {mrvBoundary && mrvBoundary.boundaries && (
                <Button
                  onClick={() =>
                    mrvBoundary.boundaries &&
                    onSetMrvAsActive(mrvBoundary.boundaries)
                  }
                  variant="secondary"
                  className="w-full"
                >
                  Set MRV as active
                </Button>
              )}

              <Button
                onClick={onBoundarySave}
                variant="primary"
                className="w-full"
                isLoading={isLoading}
              >
                Save and run check
              </Button>
            </>
          ) : (
            <>
              {selectedField.editable ? (
                <Button
                  onClick={onBoundaryEdit}
                  variant="secondary"
                  icon={"sliders"}
                  className="w-full"
                  disabled={!selectedField.editable}
                >
                  Edit active boundary
                </Button>
              ) : (
                <InfoBox variant="neutral">
                  This field has been approved for issuance and cannot be
                  edited.
                </InfoBox>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
